import React from "react";
import "./People.css";
import TeamMember from "./TeamMembers";


const directors = [
  {
    name: " Prasad Babu G",
    position: "Founder and CEO",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/prasad_babu.png",
    linkedinUrl: "https://www.linkedin.com/in/prasad-babu-627a3924/",
  },
  {
    name: "Suraj Nanneti",
    position: "Chief Business Officer",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/suraj.png",
    linkedinUrl: "https://www.linkedin.com/in/nannetisuraj",
  },
  {
    name: "Manoj G",
    position: "Chief Technology Officer",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/manoj.png",
    linkedinUrl: "https://www.linkedin.com/in/manoj-gummadi-3870b148/",
  },

  {
    name: "Jasmine G",
    position: "Director - Business Development",
    imageSrc:
    [require('../../src/assets/gcrs_images/team/jasmine.png')],
    linkedinUrl: "https://www.linkedin.com/in/jasmine-g-4635b3169/",
  },
];
const nextLeadRow = [
  {
    name: "Daniel Sananth. P",
    position: "Hydrogeologist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Sananth.png",
    linkedinUrl: "https://in.linkedin.com/in/daniel-sananth-573102187",
  },
  {
    name: "Dr. Suneel Kumar Joshi",
    position: "Director - Landscape Restoration Projects",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Suneel Kumar Joshi.png",
    linkedinUrl: "https://www.linkedin.com/in/dr-suneel-kumar-joshi-51631916/",
  },
  {
    name: "Dr. Fakira Bastia",
    position: "Environmental Geochemist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Fakira Bastia.png",
    linkedinUrl: "https://www.linkedin.com/in/dr-fakir-bastia-884b01155/",
  },
  {
    name: "Priyanka Khanna",
    position: "Water Resources Management Expert",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/priyanka2.png",
    linkedinUrl: "https://www.linkedin.com/in/priyanka-khanna-b6684932/",
  },
];
const advisors = [
  {
    name: "Dr. Rajiv Sinha",
    position: "Professor Department of Earth Sciences IIT Kanpur",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Rajiv Sinha.png",
    linkedinUrl: "https://www.linkedin.com/in/rajiv-sinha-a87a68b2/",
  },
 
  {
    name: "Dr. CBS Dutt",
    position: "Fmr. Scientist-H at National Remote Sensing Center of ISRO",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/CBS Dutt.png",
    linkedinUrl:
      "https://www.linkedin.com/in/dr-cbs-dutt-a4365bb6/?originalSubdomain=in",
  },
  {
    name: 'Sai Bhaskar Veluri',
    position: 'Technical Advisor',
    imageSrc: [require('../../src/assets/gcrs_images/team/Bhaskar_Veluri.png')],
    linkedinUrl: 'https://www.linkedin.com/in/sai-bhaskar-veluri-910a4535/',
  },

];

const teamMembers = [
  {
    name: "Adithya Ajikumar",
    position: "GIS Developer",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Adithya Aji Kumar.png",
    linkedinUrl: "https://www.linkedin.com/in/adithya-ajikumar-aba590218",
  },
  // {
  //   name: "Ajitha R",
  //   position: "Sustainability Analyst",
  //   imageSrc:
  //     "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Ajitha.png",
  //   linkedinUrl: "https://www.linkedin.com/in/ajitha-r-49336524a",
  // },

 
  // {
  //   name: " Bhavana P",
  //   position: "GIS Developer",
  //   imageSrc:
  //     "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/P Bhavana.png",
  //   linkedinUrl: "http://www.linkedin.com/in/bhavanapedada",
  // },
  {
    name: " Blessy Thanmayi CH",
    position: "Water Sector Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/blessy.png",
    linkedinUrl: "https://www.linkedin.com/in/blessy-thanmayi-77501a252/",
  },
  {
    name: "Danesh N",
    position: "Water Sector Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Dhanesh.png",
    linkedinUrl: "https://www.linkedin.com/in/nandupillidanesh",
  },
  {
    name: "Deepti Gumber Bhatnagar",
    position: "Environment & DRR Expert",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Deepthi.png",
    linkedinUrl: "https://www.linkedin.com/in/dgumber/",
  },
];

const teamFirstRow = [

 

  {
    name: "Ms. Jyoti Chaudhary",
    position: "Hydrogeologist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Jyothi Chowdary.png",
    linkedinUrl: "https://www.linkedin.com/in/jyoti-chaudhary-027a9165/",
  },
  {
    name: " Nagamani K",
    position: "HR Generalist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Nagamani.png",
    linkedinUrl: "https://www.linkedin.com/in/mani-gompa-a65256257/",
  },
  {
    name: " Latha K",
    position: "React Native Developer",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/lathaa.png",
    linkedinUrl: "https://www.linkedin.com/in/latha-kumpatla-912188228/",
  },

  {
    name: "Niten Giri",
    position: "Hydrogeologist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Niten Giri.png",
    linkedinUrl: "https://www.linkedin.com/in/niten-giri-b531b648/",
  },

];

const teamSecondRow = [

 
 

 
  {
    name: "Praveen Kumar p",
    position: "GIS Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/praveenkumar.png",
    linkedinUrl: "http://www.linkedin.com/in/praveen-kumar-pallapati",
  },

  {
    name: "Sahal K",
    position: "Hydrogeologist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Sahal_GCRS_PIC (1) 2.png",
    linkedinUrl: "https://www.linkedin.com/in/sahal-k",
  },
  {
    name: "Ahammed Sahil Shajahan",
    position: "GIS Developer",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/sahil (3).png",
    linkedinUrl: "https://www.linkedin.com/in/ahammed-sahil-36126a229/",
  },
  {
    name: "Sailendra Raju M",
    position: "GIS Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/shailendra.png",
    linkedinUrl: "https://www.linkedin.com/in/sailendra-raju-meruga-45107b24a",
  },
];
const teamThirdRow = [
 
 
 
  {
    name: "Saloni Ranka",
    position: "GIS & Water Resources Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Saloni Ranka.png",
    linkedinUrl: "https://www.linkedin.com/in/saloni-ranka-335b42186",
  },
  {
    name: "Sreenivas Bandapu",
    position: "GIS Developer",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Sreenivas B.png",
    linkedinUrl: "https://www.linkedin.com/in/sreenivas-bandapu-339a49242/",
  },
  {
    name: "Vaishnavi Sayam",
    position: "Water Sector Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Vaishnavi.png",
    linkedinUrl: "https://www.linkedin.com/in/sayam-vaishnavi-13528b188/",
  },
  {
    name: "Vamsi Balivada",
    position: "UI/UX Designer ",
    imageSrc: [require('../../src/assets/gcrs_images/team/vamsi.png')],
    linkedinUrl: "https://www.linkedin.com/in/vamsi-balivada-720256226",
  },
];

const teamFourthRow = [

 
   
  {
    name: " Naga Pavan Kumar Raja J",
    position: "GIS Analyst ",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/pavan.png",
    linkedinUrl: "https://www.linkedin.com/in/naga-pavan-2ba17b290",
  },
  {
    name: "Yoshitha K",
    position: "GIS Analyst",
    imageSrc:  [require("../../src/assets/gcrs_images/team/yoshithak.png")],
    linkedinUrl: "https://www.linkedin.com/in/yoshitha-kandregula-4075a31a9",
  },
  {
    name: "Radhitya N",
    position: "GIS Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/raditya.png",
    linkedinUrl: "https://www.linkedin.com/in/naredla-radhitya-1574351b3/",
  },
  {
    name: "Rajat Sachan",
    position: "Hydrogeologist",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/rajat.png",
    linkedinUrl: "https://www.linkedin.com/in/rajat-sachan-216852185",
  },
];

const teamFifthRow = [



   
 
 
  {
    name: "Anantha Rao",
    position: "Water Sector Analyst",
    
    imageSrc: [require("../../src/assets/gcrs_images/team/Ananth.png")],
    linkedinUrl: "https://www.linkedin.com/in/anantha-rao-metta-401035235/",
  },
  {
    name: "Likitha D",
    position: "GIS Analyst",
    imageSrc:  [require("../../src/assets/gcrs_images/team/likithad.png")],
    linkedinUrl: "https://www.linkedin.com/in/likhitha-murthy-143a42286",
  },
  {
    name: "Yathisha B",
    position: "GIS Analyst",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/yathisha.png",
    linkedinUrl: "https://www.linkedin.com/in/yathisha-buddha-3b2715227",
  },
  {
    name: "Neeharika Chauhan",
    position: "Disaster Management",
    imageSrc: [require('../../src/assets/gcrs_images/team/Neeharika.png')],
    linkedinUrl:
      "https://www.linkedin.com/in/neeharika-chauhan-308452264",
  },
];

const teamSixthRow = [
 
  
  {
    name: "Souvagya Ranjan Panda",

    position: "Hydrogeologist",
    imageSrc: [require('../../src/assets/gcrs_images/team/Panda.png')],
    linkedinUrl:
      "https://www.linkedin.com/in/souvagyarp",
  },
  {
    name: "Pujitha Sunkana",
    position: "Jr. GIS Developer",
    imageSrc:  [require("../../src/assets/gcrs_images/team/poojitha.png")],
    linkedinUrl: "https://www.linkedin.com/in/pujitha-sunkana-a58bb6256",
  },
  {
    name: "Reddy Lohitkumar",
    position: "UI/UX Designer",
    imageSrc:  [require("../../src/assets/gcrs_images/team/lohith.png")],
    linkedinUrl: "https://www.linkedin.com/in/lohitkumarreddy/",
  },
   {
    name: "Mishab M",
    position: "Hydrogeologist & Groundwater Modeller.",
    imageSrc:  [require("../../src/assets/gcrs_images/team/mishab.png")],
    linkedinUrl: "https://www.linkedin.com/in/mishab-m-939204197/",
  },
];

const teamSeventhRow = [
  // {
  //   name: "S. Sai Muskan",
  //   position: " Team Lead - Biodiversity ",
  //   imageSrc:
  //     "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/S Sai MUskan.png",
  //   linkedinUrl:
  //     "https://www.linkedin.com/in/ss-muskan?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BDIHRrJ17RTi72vmp5IapKg%3D%3D",
  // },
  
  {
    name: "Karthik EswarBabu M",
    position: "Team Lead - Water Sector Analyst",
    imageSrc: [require("../../src/assets/gcrs_images/team/karthikk.png")],
    linkedinUrl: "https://www.linkedin.com/in/karthik-eswar-8a942a230",
  },
  {
    name: "Balasubramaniyan",
    position: "Team Lead - Groundwater Modelling & Hydrogeologist",
    imageSrc:  [require("../../src/assets/gcrs_images/team/Bhala2.png")],
    linkedinUrl: "https://www.linkedin.com/in/balasubramaniyan-k-03012113b/",
  },

];
const teamEighthRow = [

 
  
];

const team9thRow = [
  {
    name: "Shivani A",
    position: "GIS Developer Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/shivani.png")],
    linkedinUrl: "https://www.linkedin.com/in/a-shivani-951204249",
  },
 

  {
    name: "Revathi Kakinada",
    position: "Software Engineer Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/revathi.png")],
    linkedinUrl: "https://www.linkedin.com/in/revathi-kakinada-906b90267?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    name: "Siri varshini Dunaboyina",
    position: "Software Engineer Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/Sirivarshini.png")],
    linkedinUrl: "https://www.linkedin.com/in/siri-varshini-496635255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    name: "Saisree B",
    position: "Software Engineer Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/SaiSree.png")],
    linkedinUrl: "https://www.linkedin.com/in/baviri-saisree-4908a2257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  
  
 
];
const team10thRow = [
  {
    name: "Anand Sarma A",
    position: "Software Engineer Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/anand.png")],
    linkedinUrl: "https://www.linkedin.com/in/anand-sarma/",
  },
  {
    name: "Likitha Y",
    position: "GIS Analyst Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/likitha.png")],
    linkedinUrl: "https://www.linkedin.com/in/likhitha-yandapalli-337903281?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
  {
    name: "SafvaMujeeb V",
    position: "GIS Analyst Intern",
    imageSrc:  [require("../../src/assets/gcrs_images/team/SafvaMujeeb.png")],
    linkedinUrl: "https://www.linkedin.com/in/safvamujeeb",
  },
  
  
];

const team11thRow = [

];

const team12thRow = [];
const teamLeads = [
  {
    name: " Jaya Surya S",
    position: "Team Lead - GIS Applications",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/Jaya Surya.png",
    linkedinUrl: "https://www.linkedin.com/in/sjsurya",
  },
  {
    name: "Sri Siva Madhuri P",
    position: " Team Lead - Remote Sensing & GIS ",

    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/madhurii.png",
    linkedinUrl:
      "https://www.linkedin.com/in/pukkella-sri-siva-madhuri-7636771aa",
  },

  {
    name: " Siva Sneha Jyothi P",
    position: " Team Lead - Remote Sensing & GIS ",
    imageSrc: [require("../../src/assets/gcrs_images/team/sneha_2.png")],
    linkedinUrl:
      "https://www.linkedin.com/in/siva-sneha-jyothi-pallaprolu-41175712a",
  },
  {
    name: "Dharma Raj T",
    position: "Team Lead - Landscape Screening Expert",
    imageSrc:
      "https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/team/dharma.png",
    linkedinUrl: "https://www.linkedin.com/in/itsdharmaraj",
  },
  
  
];

function People() {
  return (
    <div className="people-text-content">
      <h1 className="people-heading">Our Team</h1>
      <p className="people-para">
        GCRS derives its strength from a pool of highly experienced human
        resources with in-depth knowledge on issues related to environment &
        sustainability, and from 
        diverse geographies,creeds & cultures
      </p>
      <div className="people-alignment">
        <h1 className="company-directors-heading">Key Leadership</h1>
        {directors.map((member, index) => (
          <div key={index} className="">
            <TeamMember {...member} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {nextLeadRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment">
        <h1 className="adviser-heading">Advisors </h1>
        {advisors.map((advisor, index) => (
          <div key={index}>
            <TeamMember {...advisor} />
          </div>
        ))}
      </div>

      <div className="people-alignment">
        <h1 className="teamLead-heading">Team Leads</h1>
        {teamLeads.map((advisor, index) => (
          <div key={index}>
            <TeamMember {...advisor} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamSeventhRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>

      <div className="people-alignment">
        <h1 className="team-members-heading">Team </h1>
        {teamMembers.map((member, index) => (
          <div key={index} className="">
            <TeamMember {...member} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamFirstRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamSecondRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamThirdRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamFourthRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamFifthRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {teamSixthRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>

      <div className="people-alignment2">
        {teamEighthRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {team11thRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
      <div className="people-alignment">
        <h1 className="interns-heading">Interns </h1>
        {team9thRow.map((member, index) => (
          <div key={index} className="">
            <TeamMember {...member} />
          </div>
        ))}
      </div>
      <div className="people-alignment2">
        {team10thRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
     
      <div className="people-alignment2">
        {team12thRow.map((nextrow, index) => (
          <div key={index}>
            <TeamMember {...nextrow} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default People;

